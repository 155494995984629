.h1 {
  font-size: 60px;
  letter-spacing: 1px;
  font-family: $fauna-one;
  color: $color-gray;
  margin-bottom: 20px;
  padding-bottom: 17px;
}

h5 {
  font-weight: 600;
}

.area-wysiwyg {
  hr {
    color: $color-gray;
    border-top: 1px solid $color-gray;
    margin-top: 5px;
  }
  li {
    margin-top: 20px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    padding: 0 10%;
    margin-top: 20px;
    color: $color-gray;
    a {
      color: $color-dark-gray;
      font-weight: 300;
      &:hover {
        color: $color-blue;
        text-decoration: none;
      }
    }
    p, ul, ol {
      @include wysiwyg-text;
    }
  }
  .cta-button-item {
    margin-top: 0;
    a {
      font-family: $nunito-sans;
      font-size: 18px;
      color: $color-gray;
      padding-top: 0;
      &:before {
        content: '→';
        padding-right: 10px;
      }
      &:visited {
        color: $color-gray;
      }
      &:hover {
        text-decoration: none;
        color: $color-blue;
      }
    }
  }
}

.area-video__content {
  padding-top: 30px;
  @include wysiwyg-text;
  color: $color-gray;
  .img-fluid {
    width: 100%;
  }
  ul {
    list-style: none;
    padding-left: 5px;
    li {
      margin-top: 20px;
      background: url("../../img/signet-single-rose.svg") no-repeat left 1px;
      background-size: 15px;
      padding-left: 30px;
    }
  }
  .wysiwyg {
    &__text {
      a {
        color: $color-dark-gray;
        &:hover {
          color: $color-blue;
          text-decoration: none;
        }
      }
    }
    &__text--vertical {
      margin-top: 35px;
      strong {
        margin-bottom: 0px;
      }
      hr {
        color: $color-gray;
        border-top: 1px solid $color-gray;
        margin-top: 5px;
        margin-bottom: 28px;
      }
    }
  }
}

.two-column {
  .area-wysiwyg {
    &__content {
      padding: 0;
      @include wysiwyg-text;
    }
  }
}

.area-section {
  ul {
    list-style: none;
    padding-left: 5px;
    li {
      margin-top: 20px;
      background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 15 14.9'%3E%3Cg fill='%23DCD2D0'%3E%3Cpath d='M14 11.1c-.5.7-1.2 1.3-1.9 1.7-.1.1-.1.2-.2.2s-.1.1-.2.1c-.4.3-.8.6-1.3.9-.1.1-.2.1-.3.1-.2.1-.3.2-.5.3-.2.1-.3.2-.5.3-.3.2-.8 0-.9-.3v-.7c0-.3.1-.6.4-.8h.7c.7-.3 1.3-.7 1.9-1.2v-.1c.7-.6 1.4-1.2 1.9-2 .4-.5.4-1 .3-1.6-.1-.3-.1-.6-.3-.8-.2-.3-.5-.6-.8-.8l-.9-.6c-.1 0-.1-.1-.2-.1-.3-.3-.8-.5-1.4-.7-.5 0-1-.1-1.5-.2-.4 0-.7 0-1.1.1-.4.3-.5.5-.3.9.1.1.2.2.3.4.2.3.3.6.5 1 0 .1 0 .1-.1.2-.4.4-1.1.5-1.5 0-.2-.2-.3-.4-.4-.5 0-.1-.1-.1-.1-.2-.4-.7-.4-1.5-.2-2.2.4-.3.8-.5 1.2-.8.1-.1.2-.1.3-.1.9-.2 1.9-.3 2.9-.2.7.1 1.4.3 1.9.7.1.1.1.1.2.1.5.1.9.3 1.3.6.6.5 1.1 1.1 1.6 1.7l.1.1c0 .4.1.7.1 1.1.1 1.2-.2 2.2-.9 3.2 0 0 0 .1-.1.2z'/%3E%3Cpath d='M0 5.8c.1-.4.3-.7.3-1.2 0-.4.1-.7.1-1.1 0-.1.1-.2.1-.3v-.1c.2-.1.3-.3.4-.6.2-.3.4-.5.6-.8.2-.2.4-.4.5-.6l.6-.6C3.1.3 3.5.2 4 0h.2c.6.2 1 .6 1.4 1.1h.1v.2c.1.3.3.5.3.8.1.5-.2 1-.6 1.2-.4.1-.5.1-.6-.3-.2-.3-.3-.6-.5-1v-.1c-.2-.3-.4-.4-.8-.2-.1.1-.3.2-.4.3-1.3 1.7-1.8 3.7-1.2 5.8.1.3.4.6.6.8 0 .1.2.2.3.4.3.3.7.4 1.2.3 1.3 0 2.6-.4 3.8-.9.4-.2.7-.4 1.1-.6l.2-.2c.1-.2.2-.2.3-.2.2 0 .3 0 .5.1s.5.2.7.3c.1.1.3.1.3.3 0 .3 0 .6-.3.7-.5.2-.9.4-1.4.6-.5.3-1 .4-1.4.6-1.1.5-2.2.9-3.5.8-1-.1-1.9-.3-2.7-.8-.1 0-.1 0-.2-.1h-.1C1.1 9.5.8 9.3.6 9c-.1-.2-.2-.4-.2-.5-.1-.5-.2-1-.3-1.6C0 6.6 0 6.2 0 5.8z'/%3E%3C/g%3E%3C/svg%3E") no-repeat left 1px;
      background-size: 15px;
      padding-left: 30px;
    }
  }
  ol {
    list-style: none;
    padding-left: 5px;

    li {
      margin-top: 20px;
      background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Echeck%3C/title%3E%3Cpath stroke='%23dbd1cf' fill='%23dbd1cf' d='M29.756 4.125c0.144-0.194 0.337-0.288 0.581-0.288s0.438 0.094 0.581 0.288l0.869 0.794c0.144 0.194 0.219 0.412 0.219 0.65s-0.075 0.438-0.219 0.581l-21.725 21.725c-0.194 0.194-0.4 0.288-0.613 0.288s-0.425-0.094-0.613-0.288l-8.619-8.619c-0.144-0.144-0.219-0.337-0.219-0.581s0.075-0.456 0.219-0.65l0.869-0.794c0.144-0.194 0.337-0.288 0.581-0.288s0.438 0.094 0.581 0.288l7.169 7.169 20.337-20.275z'%3E%3C/path%3E%3C/svg%3E") no-repeat left 1px;
      background-size: 15px;
      padding-left: 30px;
    }
  }
}
.brick__header {
  &--headline {
    display: block;
    &--mobile {
      display: none;
    }
  }
}

// MEDIA QUERIES

@media only screen and (max-width : 768px) {
  .h1 {
    font-size: 2rem;
  }
  
  .area {
    &-wysiwyg__content {
      .two-column--text {
        margin: 20px auto;
      }
    }

    &-media--wrapper {
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width : 992px) {
  .wysiwyg__text {
    margin-top: 30px;
  }
}

@media only screen and (max-width : 550px) {
  .brick__header {
    &--headline {
      display: none;
      &--mobile {
        display: block;
        width: min-content;
        margin: auto;
        .h1 {
          font-size: 30px;
        }
      }
    }
  }
}

