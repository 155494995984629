.area-contact-form {
  .form-group {
    position: relative;
    padding-top: 15px;

    .form-check {
      padding-top: 0;
      .form-check-input {
        width: 15px;
        height: 15px;
      }
      label {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    .select-wrapper {
      border: 1px solid $color-primary;
      select {
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      label {
        top: 1px;
      }
    }

    label {
      position: absolute;
      top: 22px;
      left: 23px;
      background: #fff;
      padding: 0 5px;
      transition: top .2s;
    }

    input, textarea {
      border: 1px solid $color-primary;
      box-shadow: none;
      &::placeholder {
        opacity: 0;
      }
      &:focus, &:not(:placeholder-shown) {
        & + label {
          top: 1px;
        }
      }
    }

  }
}