/* === Bootstrap Global === */
$enable-rounded: true;

$site-wrapper-width: 1920px;

/* === Header / Navigation === */
$header-height-desktop: 142px;
$extended-header-height-desktop: 140px;
$header-space: 10px;
$top-nav-height: 40px;
$top-nav-space: 20px;


/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$color-primary: #007bff;
$color-grey: #eee;

$color-rose: #dbd1cf;
$color-rose-40: rgba(219, 209, 207, .4);
$color-gray: #706f6f;
$color-dark-gray: #323232;
$color-blue: #869cb3;
$font-white: rgb(237, 233, 232);

/* === Bricks === */
$brick-padding: 75px;
$brick-in-brick-padding: 20px;

$brick-header-bottom-space: 30px;
$brick-footer-top-space: 30px;

/* === Stage === */
$fix-slider-height: 630px;
$stage-text-color: $color-white;
$stage-navigation-color: $color-white;
$stage-navigation-active-color: $color-primary;

/* ==== Sizing ===*/
$tagline-title-size: 20px;
$tagline-text-size: 24px;
$nav-item-size: 20px;


/* ==== Font ===*/
$nunito-sans: 'Nunito Sans', sans-serif;
$fauna-one: 'Fauna One', serif;

