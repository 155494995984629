.area-teaser {
  .teaser-items {
    .pimcore_tag_block {
      display: flex;
      flex-wrap: wrap;
    }
    &--twoColumn {
      .pimcore_block_entry {
        width: 50%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--threeColumn {
      .pimcore_block_entry {
        width: 33.3333333333%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--panorama {
      .pimcore_block_entry {
        width: 100%;
        clear: none;
        padding: 0 15px;
      }
    }
  }
  &__items {
    padding: 0;
    &--slider {
      position: relative;
      padding: 0 0;
      .teaser-items__row {
        margin: 0;
        outline: none;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        outline: none;
        width: 30px;
        height: 30px;
        svg {
          width: 30px;
          height: 30px;
        }
        &.prev {
          left: -30px;
        }
        &.next {
          right: -30px;
        }
      }
    }

    &--masonry {
      margin-left: -15px;
      margin-right: -15px;
      .teaser-item {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
        margin-bottom: 15px;
        float: left;
        &--twoColumn {
          width: 50%;
        }
        &--threeColumn {
          width: 33.333333%;
        }
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $color-grey;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: $color-primary;
        }
      }
    }
  }
}

// PANORAMA

.carousel {
  &-inner {
    height: 550px;
  }
  &-control {
    &-prev,
    &-next {
      position: absolute;
      opacity: .8;

      .arrow {
        background-color: white;
      }
    }
  }
}

.panorama {
  &__controls {
    .teaser-items__row {
      margin: auto;
      height: 550px;
      width: 100%;
    }
  }

  &__arrows {
    position: absolute;
    margin-top: -285px;
    width: 730px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &__container,
  &__container--mobile{
    padding-top: 100px;
    margin: auto;
    min-width: 100%;
    min-height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__image {
    min-width: 100%;
  }

  &__hr {
    width: 200px;
    border-bottom: 1px solid white;
    margin: 9px auto;
  }

   &__text {
     display: inline;
     min-width: 360px;
     min-height: 360px;
     max-width: 360px;
     max-height: 360px;
     width: 100%;
     border-radius: 50%;
     background-color: $color-rose;
     opacity: .9;
     text-align: center;
     vertical-align: middle;
     padding-top: 110px;
     position: relative;
     transform: translateX(-50%);
     left: 50%;

     &--label {
       font-family: $fauna-one;
       font-size: 2.5rem;
       color: white;
       word-break: break-word;
       display: block;
       line-height: 1.2em;
     }

     &--headline {
       font-family: $fauna-one;
       font-size: 2.5rem;
       color: white;
       word-break: break-word;
       display: block;
       line-height: 1.2em;
     }

     &--link {
       font-family: $nunito-sans;
       font-size: 18px;
       color: $color-gray;
       //bottom: 155px;
       z-index: 20;
       &:hover {
         text-decoration: none;
         color: $color-blue;
       }
     }
   }
}


// 3 COLUMN

.area-teaser__items--slider {
  margin: auto;
  .slick-arrow.next {
    right: -5%;
  }
  .slick-arrow.prev {
    left: -5%;
  }
}

.slick-list {
  margin: auto;
}


.area-teaser__items--threeColumn,
.area-teaser__items--twoColumn {
  .arrow {
    background-color: $color-rose;
  }
  .teaser-items__row {
    margin: 40px auto;

  }
  .teaser-object {
    height: 260px;
    margin: auto;
    background-image: url("../../img/colour-gradient-overlay-01.png");
    position: relative;

    &__label {
      display: none;
    }

    &__text {
      display: none;
    }

    &__link{
      //margin-top: 0;
      font-size: 20px;
      text-transform: uppercase;
      font-family: $nunito-sans;
      text-align: center;
      width: 100%;
      bottom: 0;
      line-height: 50px;
      color: $font-white;
      background-color: $color-gray;
      position: absolute;

      &:hover{
        text-decoration: none;
        background-color: $color-blue;
      }
    }

    &--default {
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__container-link {
      width: 100%;
      height: 260px;
      background-size: cover;
      background-repeat: no-repeat;
      //margin: 40px auto;

      &:hover {
        .teaser-object {
          background-position: 0 300px;
          -webkit-transition: ease .5s;
          -moz-transition: ease .5s;
          -ms-transition: ease .5s;
          -o-transition: ease .5s;
          transition: ease .5s;

          &__link {
            background-color: $color-blue;
            -webkit-transition: ease .5s;
            -moz-transition: ease .5s;
            -ms-transition: ease .5s;
            -o-transition: ease .5s;
            transition: ease .5s;
          }
        }
      }
    }
  }
}

//EMPLOYEE

.employee-teaser-object {
  font-family: $nunito-sans;
  color: $color-gray;

  &__image {
    width: 100%;
    //height: 258px;
    height: 258px;
    background-size: cover;
    background-repeat: no-repeat;
    //background-position-x: ;
  }
  
  &__headline {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    min-height: 30px;
  }

  &__text {
    min-height: 25px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin-top: 10px;
  }
}

// MOBILE SLIDER

.teaser-items__row--mobile,
.area-teaser__items--slider
{
  display: block;
  width: 90%;
  max-width: 810px;

  .slick-slide {
    transform: scale(.89);
    transition: all .3s ease;
    padding: 0;
    width: 80%;
    max-width: 400px;
  }

  .slick-slide.slick-center,
  .slick-slide.slick-active,
  .slick-slide.slick-current {
    margin: 0;
    transform: scale(1);
  }
}

.area-teaser__items--slider,
.area-teaser__items--simple {
  .teaser-items__row {
    display: none;
  }
}

.panorama {
  &__container {
    display: none;

    &--mobile {
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__arrows {
    display: none;
  }
}

// MEDIA QUERIES

@media only screen and (min-width : 992px) {
  .panorama {
    &__container {
      display: block;

      &--mobile {
        display: none;
      }
    }
    &__arrows {
      display: block;
    }
  }
  .area-teaser__items {
    &--slider {
      width: 100%;
      max-width: none;
      .teaser-items__row {
        display: block;
      }
      .slick-slide {
        transform: none;
        transition: none;
        margin: auto;
        padding: 0 10px;
        width: 370px;
      }
    }
    &--simple {
      .teaser-items__row {
        display: block;
        &--mobile {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .teaser-items__row--mobile,
  .area-teaser__items--slider {
    width: 90%;
    max-width: 430px;
    .slick-slide {
      width: 80%;
      max-width: 260px;
    }
  }

  .teaser-object {
    &__link {
      display: block;
      height: 50px;
      &--text {
        line-height: 25px;
        display: inline-block;
      }
      &--mobile {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 550px) {


  .panorama {
    &__container,
    &__container--mobile{
      padding-top: 40px;
    }
    &__text {
      display: inline;
      min-width: 470px;
      min-height: 470px;
      max-width: 470px;
      max-height: 470px;
      width: 100%;
      border-radius: 50%;
      background-color: $color-rose;
      opacity: .9;
      text-align: center;
      vertical-align: middle;
      padding-top: 140px;
      position: relative;
      transform: translateX(-50%);
      left: 50%;

      &--label {
        font-family: $fauna-one;
        font-size: 60px;
        color: white;
        word-break: break-word;
        display: block;
        line-height: 70px;
      }

      &--headline {
        font-family: $fauna-one;
        font-size: 60px;
        color: white;
        word-break: break-word;
        display: block;
        line-height: 70px;
      }

      &--link {
        font-family: $nunito-sans;
        font-size: 18px;
        color: $color-gray;
        //bottom: 155px;
        z-index: 20;
        display: block;

        &:hover {
          text-decoration: none;
          color: $color-blue;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .employee-teaser-object {
    &__image {
      background-position: 95% 0;
    }
  }
}
//
//@media only screen and (max-width: 767px) {
//  .teaser-items__row--mobile,
//  .area-teaser__items--slider {
//    width: 90%;
//    max-width: 650px;
//    .slick-slide {
//      width: 80%;
//      max-width: 380px;
//    }
//  }
//}
