header {
  position: fixed;
  top: 0;
  z-index: 200;
  max-width: 1920px;
  width: 100%;
  padding: $header-space 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 3px solid white;
  background-color: $color-rose;
  margin-bottom: 2px;
  padding-top: 2em;
  height: $header-height-desktop;

  .header__tagline {
    margin: 0px auto 0px auto;
    width: 95%;
    max-width: 570px;
    padding: 0px 10px;
    //margin-top: 60px;
    background-repeat: no-repeat;
    height:45px;
  }

  .logo {
      height: 125px;
      width: 125px;
      border-radius: 50%;

      &--header {
        margin: 20px auto 0px auto;
        &-link {
          display: block;
        }
        &-img {
          width: 125px;
          height: 125px;
        }
      }
    }
  &.nav-up {
    //top: -$header-height-desktop;
    top: -224px;
    &.open {
      top: 0;
    }
  }
  &.animated {
    transition: top 0.3s ease-in-out;
  }

  .main-navigation {
    position: relative;

    &__list {
      display: none;
      position: relative;
      top: 50%;
      margin: 0;
      transform: translate(0, -50%);
    }

    &__item {
      position: relative;
      //margin-bottom: -32px;
      padding-bottom: 20px;
      &-text {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        &:hover {
          .main-navigation__sublist {
            display: block;
          }
        }
      }

      &:hover {
        .main-navigation__sublist {
          display: block;
        }
      }
    }

    &__sublist {
      box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);
      padding: 0px;
      display: none;
      border-top: none;
      border-radius: 0;
      left: 5px;
      top: 53px;
      min-width: 320px;
      max-width: 320px;
      font-size: 18px;
      position: absolute;
      background-color: $color-white;
      margin: -15px 0 0 0;
    }

    &__subitem {
      padding: 0 25px;
      text-transform: capitalize;

      &:hover {
        background-color: $color-rose-40;
        color: $color-gray;
      }
    }

    &__sublink {
      //border-bottom: 1px solid white;
      color: $color-gray;
      padding: 10px 20px 10px 0px;
      max-width: 300px;
      font-weight: 400;
      display: block;
      word-break: break-word;
      white-space: normal;

      &-text {
        font-size: 18px;
      }

      &--no-border {
        border-bottom: none;
      }
    }
    .language-switch {
      line-height: 34px;

      &__item {
        color: $color-white;
        font-weight: 300;
        display: inline;

        &:before {
          content: '|';
          padding-right: 5px;
        }

        &--selected {
          color: $color-gray;
          font-weight: 600;
          display: inline;
        }
      }
    }
  }
}


.navbar-light {
  .navbar-toggler {
    border-color: $color-gray;
  }
}
.navbar {

  position: relative;
  &-toggler {
    display: block !important;
    position: absolute;
    border: 1px solid $color-gray;
    right: 20px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    &-icon {
      width: 30px;
    }
  }
}

.nav {
  margin: auto;
  font-family: $nunito-sans;
  &-item {
    margin-top: 17px;
    
    a:hover {
      text-decoration: none;
    }
    &:hover {
      .main-navigation__sublist {
        display: block;
      }
      .nav-link {
        color: $color-blue !important;
      }
    }
  }
  .navbar-nav {
    .collapse.navbar-collapse {
      display: none;
    }

    .dropdown-item:hover {
      background-color: transparent;
      color: $color-gray;
    }
  }
  .languages {
    display: inline-block;
    position: absolute;
    .nav-link {
      color: white;
    }
  }
}

.navbar {
  padding: 0;
}

//MM-MENU
.mm-menu {
  .mm-panel_opened {
    padding-right: 0;
  }

  .mm-listitem {
    color: $color-gray;
    border-color: $color-gray;
    margin: 0;

    h3 {
      font-weight: 300;
      line-height: 10px;
    }
    a {
      padding-left: 20px;
      line-height: 48px;
    }
    .language-switch {
      font-weight: 300;
      line-height: 34px;
      width: min-content;

      &__item {
        color: $color-gray;
        display: inline;
        padding-left: 0;
        font-size: 16px;

        &:before {
          content: '|';
          padding-right: 5px;
        }

        &--link {
          display: inline;
          padding-left: 0;
          
          &--selected {
            display: inline;
            padding-right: 6px;
          }
        }

        &--selected {
          font-weight: 600;
          color: $color-dark-gray;
          display: inline;
          padding-right: 0;
          font-size: 16px;

        }
      }
    }
  }

  .main-navigation {
    &__item {
      width: 100%;
      font-weight: 200;
      &-text {
        font-size: 16px;
        font-weight: 400;
      }
    }
    &__sublist {
      display: block;
      margin: 0;
      width: 100%;
      background: none;
      border: none;
    }

    &__subitem {
    }

    &__sublink {
      width: 90%;
      &-text {
        text-transform: capitalize;
        font-weight: 300;
        font-size: 16px;
        line-height: 34px;
        margin-bottom: 0;
        word-break: break-word;
        white-space: normal;
      }
    }
  }
  .dropdown-menu {
    color: $color-gray;
    top: auto;
    display: block;
    padding: 0;
  }
  .mm-panels>.mm-panel>.mm-listview {
    margin: 0;
  }
  .mm-panel {
    padding: 40px 0;
    &:before {
      height: 0px;
    }
  }
}

.dropdown-toggle {
  svg {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-width : 1025px) {
  .header {
    &__tagline {
      margin-bottom: 22px;
    }
  }

  .navbar {
    max-width: 605px;
    width: 100%;
    margin: 40px auto 0 auto;
    .languages {
      //margin-top: -25px;
      //margin-left: 150px;
      right: -150px;
      top: 0px;
      display: block;
    }
    &-toggler {
      display: none !important;
    }
  }

  header .logo {
    &--header {
      display: block;
      position: absolute;
      //margin-top: -10px;
      margin-left: -190px;
      bottom: 0px;
      top: -75px;
    }
  }

  .main-navigation {
    &__list {
      padding-left: 10px;
      display: block !important;
    }
  }
}
