.area-blurbs {
  &__blurbs-wrapper {
    display: none;
  }
}

.area-blurbs__blurbs-wrapper--mobile {
  display: block;
  max-width: 810px;
  width: 90%;
  margin: auto;

  .slick-slide {
    margin-top: 100px;
    transform: scale(.89);
    transition: all .3s ease;
    padding: 0 20px;
    width: 80%;
    max-width: 400px;
  }

  .slick-slide.slick-center,
  .slick-slide.slick-active,
  .slick-slide.slick-current {
    transform: scale(1);
  }
}

.blurb {
  text-align: center;
  display: inline-block;
  
  a {
    &:hover {
      text-decoration: none;
    }
  }

  &__row {
    margin: 100px 0;
  }

  &--border {
    border: 1px solid $color-gray;
    height: 100%;
    top: 50px;
  }

  &__icon {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 25;
    top: -58px;


    .svg-inline--fa {
      width: 65px !important;
      height: 65px !important;
      color: $color-rose !important;
    }
  }
  &__content {
    &--text {
      padding: 50px 0 30px 0;
    }
  }


  &__headline,
  &__headline--mobile {
    margin-top: 0;
    @include wysiwyg-text;
    color: $color-gray;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 400;
    height: 100px;
    padding: 0px 20px 0 20px;
    line-height: 30px;
    display: table;
    width: 100%;

    &--text {
      display: table-cell;
      //height: 100%;
      //line-height: 30px;
      text-align: center;
      vertical-align: middle;
    }

    &:hover {
      color: $color-blue;
    }
  }

  &__text {
    @include wysiwyg-text;
    font-family: $nunito-sans;
    font-weight: 200;
    padding: 0 20px;
  }

  &__circle {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid $color-gray;
    transform: rotate(-45deg);
    margin: auto;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -100px;


    &--cover {
      z-index: 10;
      background: white;
      width: 150px;
      height: 100px;
      margin: auto;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: -100px;
    }
  }
}

.blurb {
  &__headline {
    display: table;
    &--mobile{
      display: none;
    }
  }
}

// MEDIA QUERIES
@media only screen and (min-width : 1201px) {
  .area-blurbs {
    &__blurbs-wrapper {
      display: block;
      .blurb {
        max-width: 700px;
      }
      &--mobile {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .area-blurbs__blurbs-wrapper--mobile {
    width: 90%;
    max-width: 650px;
    //.blurb {
    //  &__headline {
    //    font-size: 1.3em;
    //  }
    //}
    .slick-slide {
      width: 80%;
      max-width: 330px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .area-blurbs {
    &__blurbs-wrapper {
      display: block;
      .blurb {
        margin-top: 100px;
      }
      &--mobile {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px){
  .area-blurbs {
    &__blurbs-wrapper {
      &--mobile {
        width: 90%;
        max-width: 1000px;
        .slick-slide {
          width: 80%;
          max-width: 500px;
        }
      }
    }
  }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
  .blurb {
    &__headline {
      display: none;
      &--mobile{
        display: table;
      }
    }
  }
}