section.header-stage {
  padding: 0;

  .stage-snippet {
    position: relative;
    overflow: hidden;
    &--full {
      height: calc(100vh - #{$header-height-desktop});
      .stage-snippet__slide-background {
        height: calc(100vh - #{$header-height-desktop});
      }
    }
    &--fix {
      height: $fix-slider-height;
      .stage-snippet__slide-background {
        height: $fix-slider-height;
      }
    }
    &--fixSmall {
      height: 286px;
      .stage-snippet__slide-background {
        height: 286px;
      }
    }
    &__slider {
      padding: 0;
      margin: 0;
      height: 100%;
      position: relative;
      .slick-arrow {
        position: absolute;
        margin-top: 40vh;
      }
      .slick-arrow.next {
        right: 10%;
      }

      .slick-arrow.prev {
        left: 10%;
        z-index: 10;
      }
      .slick-list {
        height: 100%;
        width: 100%;
        margin: auto;
        display: block;
        position: absolute;
        .slick-track {
          height: 100%;
        }
      }
    }
    &__slide {
      height: 100%;
      outline: none;
      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
      }
      &-content {
        position: relative;
        padding: 40px;
        top: 50%;
        transform: translateY(-50%);
        &-subHeadline {
          font-size: 35px;
          color: $stage-text-color;
        }
        &-headline {
          //font-size: 45px;
          color: $stage-text-color;
        }
        .stage-snippet__slide-buttons {
          .cta-button-item {
            margin: 0 10px;
            text-align: center;
          }
        }
        .stage-snippet__slide-quick-links {
          .quick-link-item {
            &__icon {
              text-align: center;
              margin-bottom: 10px;
              svg {
                width: 80px;
                height: 80px;
                color: $stage-text-color;
              }
            }
            &__text {
              text-align: center;
              transform: rotate(-45deg);
              color: $stage-text-color;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 60px;
      padding: 0;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $stage-navigation-color;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: $color-blue;
        }
      }
    }
  }
}

#siteWrapper.top-navigation {
  section.header-stage {
    .stage-snippet--full {
      //height: calc(100vh - #{$extended-header-height-desktop});
      .stage-snippet__slide-background {
        //height: calc(100vh - #{$extended-header-height-desktop});
      }
    }
  }
}

section.header-stage {
  width: 100%;

  .stage-snippet {
    font-family: $fauna-one;
    &__slide {
      position: relative;
      &-background {
        //height: calc(85vh - 80px);
        height: 100%;
      }
      &-content {
        margin: auto;
        &-headline {
          font-size: 3.5rem;
          color: white;
          line-height: 75px;
          padding-top: 20px;
          max-width: 700px;
          margin: auto;
        }
        .stage-snippet__slide-buttons {
          .btn-secondary {
            margin-top: 15px;
            border-radius: 25px;
            width: fit-content;
            line-height: 26px;
            border: 1px solid white;
            &:before {
              content: '→';
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .arrow {
    background-color: $color-rose;
    color: $color-gray;

    &__container {
      display: inline-block;
      position: relative;
      bottom: 45px;
    }

    &__text {
      transform: rotate(-45deg);
      font-family: $nunito-sans;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      margin-left: 5px;
      margin-top: 35px;
      text-transform: capitalize;

    }

    &--down {
      width: 90px;
      height: 90px;
      border-radius: 100px 0 0 0;
      margin: 0 48px;

      a {
        color: $color-gray;
        &:visited {
          color: $color-gray;
        }
      }

      &:hover {
        background-color: $color-blue;
        .arrow__text {
          color: white;
        }
      }
    }
  }
}

.quicklink--active {
  .arrow {
    &--down {
      background-color: $color-blue !important;
    }
    &__text {
      color: white;
    }
  }
}

.stage {
  &__mobile {
    display: none;
  }
  &__desktop {
    display: block;
  }
}

//MEDIA QUERIES
@media only screen and (max-width : 768px) {
 .d-flex.justify-content-center.arrow__container {
   display: none !important;
 }
}



@media only screen and (min-width: 1px) and (max-width : 450px) {
 .stage {
   &__mobile {
      display: block;
    }
   &__desktop {
     display: none;
   }
 }

  section.header-stage .stage-snippet {
    &__slide {
      &-content-headline {
        font-size: 2em;
        line-height: 1.2em;
      }

      &-buttons {
        margin-top: 0px;
      }
    }
    
    .slick-dots {
      display: none;
    }
  }

  .logo--header {
    transform: scale(.8);
    margin-top: 0px !important;
  }
  .navbar-toggler {
    margin-top: 10px;
  }

}
