.area-logo-bar {
  &__logo {
    width: auto;
    display: inline-block;
    margin: 0 15px;
    position: relative;
  }
  &__image {
    width: auto;
    &--color{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      &:hover{
        opacity: 1;
      }
    }
    &--grey{
      opacity: 1;
      &:hover{
        opacity: 0;
      }
    }
  }
  .slick-arrow {
    &:before{
      content: '';
    }
    &.slick-prev {
      margin-left: -15px;
    }
    &.slick-next {
      margin-right: -15px;
    }
    .icon-arrow-left_m,
    .icon-arrow-right_m {
      width: 20px;
      height: 20px;
      use {
        fill: $color-grey;
      }
    }
  }
}

@media(min-width: map-get($grid-breakpoints, lg)) {
  .area-logo-bar {
    .slick-arrow {
      .icon-arrow-left_m,
      .icon-arrow-right_m {
        width: 15px;
        height: 15px;
      }
    }
  }
}