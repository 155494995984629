/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $nunito-sans;
    color: $color-gray;

    .h1, h1 {
        font-size: 60px;
        font-family: $fauna-one;
    }

    .h2, h2 {
        font-size: 20px;
        font-family: $nunito-sans;
    }

    .h3, h3 {
        font-size: 20px;
        font-weight: 600;
        font-family: $nunito-sans;
    }

    .h4, h4 {
        font-size: 18px;
        font-family: $nunito-sans;
    }
}

.hidden {
    display: none !important;
}

// form error stylings
.has-error {
    .form-control-feedback {
        color: $danger;
    }
    .form-control {
        border-color: $danger;
    }
}
.has-warning {
    .form-control-feedback {
        color: $warning;
    }
    .form-control {
        border-color: $warning;
    }
}
.has-success {
    .form-control-feedback {
        color: $success;
    }
    .form-control {
        border-color: $success;
    }
}

/******************
 * COOKIE POLICY
 ******************/
#pc-cookie-notice {

    #pc-button {
        button {

        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}