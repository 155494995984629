@import "../common/_variables.scss";

footer {
  margin-top: 60px;
  padding: 55px 0 0 0;
  background-color: $color-rose;
  font-family: $nunito-sans;
}

.footer {
  &__content--border {
    border-top: 1px solid white;
    padding-top: 22px;
  }
  &__tagline {
    height: 45px;
    width: 100%;
    max-width: 570px;
    background-repeat: no-repeat;
    margin: auto auto 22px auto;
  }
  &__contact-info {
    a {
      color: $color-gray;
      &:hover {
        color: $color-blue;
        text-decoration: none;
      }
      &:visited {
        color: $color-gray;
        &:hover {
          color: $color-blue;
        }
      }
    }
    p,
    address {
      width: fit-content;
      margin: auto;
      font-size: 16px;
      font-weight: 200;
      color: $color-gray;
      text-align: center;
    }
  }
  &__links {
    display: none;
  }
  &__link {
    padding-bottom: 5px;
    border-bottom: 1px solid $color-gray;
    margin-bottom: 26px;
    font-weight: 200;
    font-size: 20px;
    &--anchor-text {
      font-weight: 200;
      font-size: 20px;
    }
    svg {
      float: right;
      fill: $color-gray;
      height: 20px;
      display: inline;
      width: 15px;
      margin-top: 3px;
    }
    &-list {
      list-style: none;
      padding: 0;
    }
    &--anchor {
      color: $color-gray;
      &:hover {
        text-decoration: none;
        color: $color-blue;
      }
      &:visited {
        color: $color-gray;
      }
    }
  }
  &__logo {
    margin: -100px auto auto auto;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 85px;
    height: 85px;
  }
}

.legal {
  &__container {
    background-color: gray;
    min-width: 100%;
    padding: 10px 0;
    min-height: 45px;
    color: $font-white;
    text-align: center;
    top: 50%;
    margin-top: 32px;
    font-size: 16px;
    font-weight: 200;
  }

  &__links {
    margin: 0;
    display: inline;
    padding: 0;
  }

  &__text {
    display: inline;
    margin: 0;
  }

  &__link {
    display: inline;
    color: $font-white;
    text-decoration: none;
    &:hover {
      color: $color-blue;
      text-decoration: none;
    }
  }
}



@media only screen and (min-width: 769px) {
  .footer {
    &__links {
      display: inline-block;
    }
    &__link {
      max-width: 300px;
      margin-lefT: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: 1201px) {
  .footer {
    &__contact-info {
      p,
      address{
        text-align: left;
        margin-left: 20px;
      }
    }
  }
}